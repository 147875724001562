import Axios, { AxiosInstance } from 'axios';
import moment from 'moment';
import bigDecimal from 'js-big-decimal';

export const IS_DESKTOP = true;
export const DEFAULT_HOUR = 4;
export const DAY_MS = 1000 * 3600 * 24;

export const priceFormat = (price: number | bigDecimal | string | undefined | null) => {
  let number = 0;

  if (price) {
    if (typeof price === 'number') {
      number = price;
    } else if (typeof price === 'string') {
      number = parseFloat(price)
    } else if (price.constructor.name === 'bigDecimal') {
      number = parseFloat(price.getValue())
    }
  }

  return new Intl
    .NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' })
    .format(number)
    .replace('.', ',')
}

export const axios = Axios.create({
  baseURL: '/api',
  timeout: 60000
}) as AxiosInstance;

(axios as any).CancelToken = Axios.CancelToken;
(axios as any).isCancel = Axios.isCancel;

export const nivoColorScheme = ['rgb(31, 119, 180)', 'rgb(255, 127, 14)', 'rgb(44, 160, 44)', 'rgb(214, 39, 40)', 'rgb(148, 103, 189)', 'rgb(140, 86, 75)', 'rgb(227, 119, 194)', 'rgb(127, 127, 127)', 'rgb(188, 189, 34)', 'rgb(23, 190, 207)'];
export const colorScheme = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"];

export const getColor = (bar: any) => nivoColorScheme[bar.index%10];

export const diffDays = (date1: Date, date2: Date) => {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  return diffDays;
}

export const getQuarter = (date: Date): [number, number] => {
  const year = date.getFullYear();
  const month = date.getMonth();
  if (month < 3) {
    return [1, year ]
  } else if (month < 6) {
    return [2, year ]
  } else if (month < 9) {
    return [3, year ]
  } else {
    return [4, year ]
  }
}

export const startOfQuarter = (date: Date) => {
  const month = date.getMonth();
  const result = resetDate(date);

  if (month < 3) {
    result.setMonth(0);
  } else if (month < 6) {
    result.setMonth(3);
  } else if (month < 9) {
    result.setMonth(6);
  } else if (month < 12) {
    result.setMonth(10);
  } else {
    result.setMonth(9);
  }

  return result;
}

export const endOfQuarter = (date: Date) => {
  const month = date.getMonth();
  const result = resetDate(date);

  if (month < 3) {
    result.setMonth(3);    
  } else if (month < 6) {
    result.setMonth(6);
  } else if (month < 9) {
    result.setMonth(9)
  } else if (month < 12) { 
    result.setMonth(11);
    result.setDate(31);
  } else {
    result.setMonth(0);
  }

  return moment(result).subtract(1, 'second').toDate();
}

export const resetDate = (date: Date) => {
  const result = new Date(date);
  result.setDate(1);
  result.setHours(0);
  result.setMinutes(0);
  result.setSeconds(0);
  result.setMilliseconds(0);
  return result
}

export const bytesToMB = (bytes: number) => Math.ceil(bytes / (1024 * 1024));
export const bytesToGB = (bytes: number) => Math.ceil(bytes / (1024 * 1024 * 1024))

export function arrayMove(arr: any[], old_index: number, new_index: number) {
  const element = arr[old_index];
  arr.splice(old_index, 1);
  arr.splice(new_index, 0, element);
}
