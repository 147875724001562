import { Button, Classes, Dialog, Icon } from '@blueprintjs/core';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import JustifiedGrid from 'react-justified-grid';
import { IconModel } from '../../models/icon.model';
import { IconRepository } from '../../repository';
import { IconImage } from '../settings/icons';

export interface Props extends WithTranslation {
  isOpen: boolean;
  onSelect: (icons: string[]) => void;
  onClose: () => void;
}

export interface State {
  icons: IconModel[];
  images: IconImage[];
  selection: string[];
}

export class IconPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      icons: [],
      images: [],
      selection: []
    }
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const { items: icons } = await IconRepository.index(
      1,
      1000,
      'order',
      'asc',
      {}
    );

    const images = icons.map(icon => ({
      src: `https://print.jit.fi/images/icons/${icon.fileName}`,
      width: 100,
      height: 100,
      alt: icon.fileName
    }));

    this.setState({ icons, images })
  }

  private close = () => {
    this.props.onClose()
  }

  private select = (image: IconImage, index: number) => {
    const selection = [...this.state.selection];
    const icon = this.state.icons[index];
    if (!selection.includes(icon.fileName)) {
      selection.push(icon.fileName)
    }
    this.setState({ selection })
  }

  private import = () => {
    this.props.onSelect(this.state.selection)
  }

  public render() {
    return (
      <Dialog title="Global Icon Pool" isOpen={this.props.isOpen} onClose={this.close} className="bp3-dark" style={{ width: 1200 }}>
        { this.state.icons.length > 0 && <JustifiedGrid
          images={this.state.images}
          maxRowHeight={100}
          gutter={1}
          showIncompleteRow={true}
          style={{ marginBottom: 24 }}>
          {processedImages => {
            return processedImages.map((image, index) => {
              const { src, width, height, left, top } = image;
              const icon = this.state.icons[index];
              const selected = this.state.selection.includes(icon.fileName);
              const className = '';
              return (
                <div 
                  className={className}
                  key={`pool-image-${index}`}
                  onClick={() => this.select(image, index)}
                  style={{ width, height, display: 'inline' }}>
                  <img
                    alt="icon"
                    src={src}
                    width={width}
                    height={height}
                    style={{ 
                      position: 'static'
                    }}
                  />
                  { selected && <div className='img-check' style={{
                    width: 32,
                    height: 32,
                    background: 'green',
                    position: 'absolute',
                    zIndex: 10,
                    top: top + 64,
                    left: left + 62,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 16
                  }}>
                    <Icon icon='tick' color="white" />
                  </div> }
                </div>
              )
            })
          }}
        </JustifiedGrid> }
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="IMPORT SELECTED" intent="success" onClick={this.import}/>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(IconPicker)