import * as React from 'react';
import { Select } from '@blueprintjs/select';
import { FormGroup, Button, MenuItem } from '@blueprintjs/core';
import { useEffect } from 'react';
import { Category } from '../models/category.model';
import { axios } from '../session';
import { WithTranslation, withTranslation } from 'react-i18next';

const BlueprintSelect = Select.ofType<Category>();

export interface Props extends WithTranslation {
  inline?: boolean;
  label?: string;
  category: Category;
  parent?: Category;
  ignoreParent?: boolean;
  onChange: (category: Category) => void;
}

export function CategorySelect({
  onChange,
  category,
  label,
  parent,
  inline = false,
  i18n,
  ignoreParent
}: Props) {
  const [categories, setCategories] = React.useState([] as Category[]);

  useEffect(() => {
    const controller = new AbortController();
    const fetch = async () => {
      let params = '';
      console.log("figure out for parent", parent?.id);
      // const params = parent && parent.exists ? `category_id=${parent.id}` : 'category_id=top';

      if (parent && parent.exists) {
        params = `category_id=${parent.id}`
      }

      if ((!parent || !parent.exists ) && !ignoreParent) {
        params = 'category_id=top'
      }

      // if (parent && parent.parent && parent.parent.exists) {
      //   console.log('p1', category);
      //   params = `category_id=${parent.id}`
      // } else if (parent && !parent.parent?.exists) {
      //   console.log('p2', category);
      //   params = `category_id=${parent.id}`
      // } else {
      //   console.log('p3', category);
      //   params = 'category_id=top'
      // }

      console.log('category select =>', params, category, parent);
      
      const { data } = await axios.get(`/categories?${params}`, {
        signal: controller.signal,
      });
      const mapped = data.categories.map((c: unknown) => new Category(c));
      mapped.splice(0, 0, new Category({ name: i18n.t('blank_category'), parent: parent }))
      setCategories(mapped);
    };

    fetch();
    return () => {
      controller.abort();
    };
  }, [ parent, category, i18n, ignoreParent ]);

  return (
    <FormGroup inline={inline} label={label} className='m-b-0'>
      <BlueprintSelect
        filterable={false}
        items={categories}
        activeItem={category}
        itemRenderer={(item, options) => (
          <MenuItem
            key={item.id}
            text={item.name}
            label={item.parent?.name}
            onClick={options.handleClick}
          />
        )}
        onItemSelect={(_category) => {
          onChange(_category);
        }}
      >
        <Button
          text={category.exists ? category.name : (parent && parent.exists ? 'Select Subcategory' : 'Select Category')}
          rightIcon="chevron-down"
          minimal
          className='m-b-0'
        />
      </BlueprintSelect>
    </FormGroup>
  );
}

export default withTranslation()(CategorySelect);