import { BaseModel, Selectable, Resource } from '@patterns/core'
import { Category } from './category.model';

export interface IProductImage {
  id: string;
  fileName: string;
  order: number;
}

@Resource('products')
export class Product extends BaseModel implements Selectable {
  remoteId: number;
  groupId: string;
  internalCode: string;
  barcode: string;
  nameLatin: string;
  nameLatinSort: string;
  name1: string;
  name2: string;
  color: string;
  color2: string;
  growZone: string;
  hardiness: string;
  ground: string;
  ground2: string;
  originCountry: string;
  blossom: string;
  plantSize1: string;
  plantSize2: string;
  plantHeight: string;
  plantWidth: string;
  age: string;
  price: string;
  quantity: string;
  priceGroupId: string;
  notes: string;
  packSize: string;
  packWeight: string;
  printQty: number;
  lastChanged: Date;
  notes2: string;
  urls: string[];
  extras: any;
  icons: IProductImage[];
  images: IProductImage[];
  globalIcons: string[];
  organization_id: string;
  category: Category;

  constructor(data: any = {}) {
    super(data)
    this.remoteId = data.remoteId;
    this.groupId = data.groupId;
    this.internalCode = data.internalCode;
    this.barcode = data.barcode;
    this.nameLatin = data.nameLatin;
    this.nameLatinSort = data.nameLatinSort;
    this.name1 = data.name1;
    this.name2 = data.name2;
    this.color = data.color;
    this.color2 = data.color2;
    this.growZone = data.growZone;
    this.hardiness = data.hardiness;
    this.ground = data.ground;
    this.ground2 = data.ground2;
    this.originCountry = data.originCountry;
    this.blossom = data.blossom;
    this.plantSize1 = data.plantSize1;
    this.plantSize2 = data.plantSize2;
    this.plantWidth = data.plantWidth;
    this.plantHeight = data.plantHeight;
    this.age = data.age;
    this.price = data.price;
    this.quantity = data.quantity;
    this.priceGroupId = data.priceGroupId;
    this.notes = data.notes;
    this.packSize = data.packSize;
    this.packWeight = data.packWeight;
    this.printQty = data.printQty;
    this.lastChanged = data.lastChanged ? new Date(data.lastChanged) : new Date();
    this.notes2 = data.notes2;
    this.images = data.images || [];
    this.icons = data.icons || [];
    this.organization_id = data.organization_id;
    this.urls = data.urls || [];
    this.extras = data.extras || {};
    this.globalIcons = data.globalIcons || [];
    this.category = data.category ? new Category(data.category) : new Category();
  }

  getLabel() { return this.name1 }
  getTitle() { return this.nameLatin }
}
